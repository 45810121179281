$(document).ready(function () {
    loginPageHeight();
    alphabet_list_height();
    compare_result_height();
    homeContentHeight();
    sidebar_height()

    $('.menu-trigger').on('click', function () {
        $('body').toggleClass('menu--open');
        nav_height();
    });

    //Menu Tooltips
    if ($('[data-toggle="tooltip"]').length) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    //Add favorite class
    $('.add-favorite').on('click', function () {
        $(this).toggleClass('active');
    });

    //A to Z click
    $('.atoz-sort').on('click', function () {
        $(this).addClass('active');
    });

    //Favorite Tooltips
    if ($('.custom-tooltip-wrape').length) {
        setTimeout(function () {
            $('.custom-tooltip').hide();
        }, 10000);
        $('.tooltip-close').on('click', function () {
            $(this).parent().hide();
        });
        $('.custom-tooltip-toggle').hover(function () {
            $('.custom-tooltip').css("display", "block");
        });
    }

    // Edition Selection
    $(".active-edition").on('click', function () {
        $(this).parent().toggleClass('dropdown-open').find('ul').first().slideToggle('fast');
    });

    // exapand toggle
    $('.action-button .expand').on('click', function () {
        $(this).toggleClass('open');
//      $(this).parents('li').siblings().find('.custom-accodion-descripttion').slideUp();
        $(this).parents('li').find('.custom-accodion-descripttion').slideToggle();
    });
    // remove row
    $('.action-button .delete').on('click', function () {
        $(this).parents('li').remove();
    });

    $('.favorite-list .action-button .add-favorite').on('click', function () {
        $(this).parents('li').remove();
    });

    //Search
    $('#search').on('keyup', function () {
        if (this.value.length > 0) {
            $(this).parent().siblings('.form-group').find('.reset').show();
        } else {
            $('.reset').hide();
        }
    });
    $('.search-trigger').on('click', function () {
        $(this).parents().find('.search-wrap').slideToggle();
    });

    // Smooth scroll to internal page section
    $('.alphabet-trigger').bind('click', function (event) {
        $(this).parent().siblings().removeClass('active-alphabet');
        $(this).parent().toggleClass('active-alphabet');
        var anchor = $(this);
        if (win_width() < 767) {
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - 96)
            }, 800);
        } else {
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - header_height() - 55)
            }, 800);
        }
        event.preventDefault();
    });

    // custom scrollbar
    if ($('.sidebar-with-costum-scrollbar ul').length) {
        $('.sidebar-with-costum-scrollbar').mCustomScrollbar({
            scrollButtons: {enable: true}
        });
    }
    // sidebar togggle
    $('.sidebar-toggle').on('click', function () {
        $(this).toggleClass('active');
        $('body').toggleClass('right-sidebar-open');
    });
    // Sidebar anchor scrolling
    $('.sidebar-with-costum-scrollbar li a').bind('click', function () {
        $(this).parent().siblings().removeClass('active');
        $(this).parent().toggleClass('active');
        var anchor = $(this);
        if (win_width() < 767) {
            $(this).parents().find('.sidebar-toggle').removeClass('active');
            $('body').removeClass('right-sidebar-open');
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - 115)
            }, 800);
        } else if (win_width() < 1199) {
            $(this).parents().find('.sidebar-toggle').removeClass('active');
            $('body').removeClass('right-sidebar-open');
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - 133)
            }, 800);
        } else {
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - header_height() - 16)
            }, 800);
        }

    });

    // hilfe anchor scrolling
    $('.internal-linking li a').bind('click', function () {
        $(this).parent().siblings().removeClass('active');
        $(this).parent().toggleClass('active');
        var anchor = $(this);
        if (win_width() < 767) {
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - 91)
            }, 800);
        } else {
            $('html, body').stop().animate({
                scrollTop: ($(anchor.attr('href')).offset().top - header_height() + 1)
            }, 800);
        }
    });

    $('.modal-body .group-name').on('click', function () {
        $(this).addClass('checked');
    });

    //Compare option trigger
    $('.compare-option-trigger').on('click', function () {
        $(this).parent().find('.compare-option-content').slideToggle('fast');
    });

});

$(window).resize(function () {
    if ($('.menu--open').length) {
        win_height();
    }
    nav_height();
    loginPageHeight();
    alphabet_list_height();
    compare_result_height();
    homeContentHeight();
    sidebar_height();
});

$(document).on("touchstart click", function (e) {
    container = $('.edition-selection-wrap, .compare-option-content, .compare-option-trigger');
    contactInfo = $('.edition-selection-wrap, .compare-option-content, .compare-option-trigger');
    if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0 && !contactInfo.is(e.target) && contactInfo.has(e.target).length === 0)
    {
        $('.edition-selection').removeClass('dropdown-open').find('ul').slideUp('fast');
        if (win_width() < 992) {
            $('.compare-option-content').parent().find('.compare-option-content').slideUp('fast');
        }
    }
});

function nav_height() {
    if (win_width() < 993 && $('.menu--open').length) {
        $('.left-navbar').css('height', win_height() - header_height() + 1);
    }
}

function homeContentHeight() {
    $('.home-content').css('min-height', win_height() - header_height() + 1);
}

function loginPageHeight() {
    $('.login-page-img').css('height', win_height());
}

function alphabet_list_height() {
    $('.alphabet-list').css('height', win_height() - 92);
}

function compare_result_height() {
    if (win_width() < 1025) {
        $('.compare-result').css('height', win_height() - header_height() - 90);
    } else {
        $('.compare-result').css('height', win_height() - header_height() - 100);
    }
}

function sidebar_height() {
    if (win_width() < 767) {
        $('.sidebar-with-costum-scrollbar').css('height', win_height() - header_height() - 30);
    } else {
        $('.sidebar-with-costum-scrollbar').css('height', win_height() - header_height() - 70);
    }
}

function win_height() {
    return $(window).outerHeight();
}
function win_width() {
    return $(window).width();
}
function header_height() {
    return $('header.page').outerHeight();
}

